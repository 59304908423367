import React, { useState } from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import tw, { styled } from 'twin.macro'

import { MainLayout } from '@common/layouts'
import { CTA, Article } from '@common/components'
import { SEO } from '@components'
import NewMainLayout from '../../layouts/NewMainLayout'

export const query = graphql`
  query ArticleQuery($id: Int, $tags: [Int]) {
    article(strapiId: { eq: $id }) {
      migrationId
      title
      slug
      metaDescription
      createdAt: created_at(formatString: "MMMM D, YYYY")
      publishedAt(formatString: "MMMM D, YYYY")
      author {
        id
        name
        avatar {
          childImageSharp {
            fixed(width: 40, height: 40) {
              ...GatsbyImageSharpFixed_withWebp
            }
          }
        }
      }
      image {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      tags {
        id
        name
        slug
      }
      childMarkdownRemark {
        excerpt(pruneLength: 320)
        html
        timeToRead
      }
      headerYoutubeVideo
      autoplayHeaderYoutubeVideo
    }
    allArticle(
      limit: 3
      filter: {
        tags: { elemMatch: { id: { in: $tags } } }
        strapiId: { ne: $id }
      }
    ) {
      nodes {
        id
        title
        slug
        image {
          childImageSharp {
            fluid(maxWidth: 311) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`

const Center = styled.div`
  ${tw`flex mx-auto max-w-screen-xl items-center pt-16`}
`

const Grid = styled.div`
  ${tw`grid grid-cols-1 mx-auto`}
  ${tw`md:(px-8 grid-cols-article)`}
`

const GridFlex = styled.div`
  ${tw`flex flex-col mx-auto max-w-screen-md items-center pt-16 px-6`}
  ${tw`md:(px-8)`}
`

const ArticleWrapper = styled.article`
  ${tw`flex flex-col px-8`}
`

const MainImage = styled(Img)`
  ${tw`w-full mx-auto mb-12`}
`

const Title = styled.h1`
  ${tw`text-3xl font-bold tracking-tight leading-10 mb-10`}
  ${tw`md:(text-5xl tracking-tighter leading-18)`}
`

const Content = styled.section`
  ${tw`prose prose max-w-none text-left leading-6 antialiased md:text-justify`}

  img {
    ${tw`mt-0 mb-0`}
  }
`

const handleYoutubeUrl = (article) => {
  let videoUrl
  try {
    let articleHeaderYoutubeVideo = article.headerYoutubeVideo
    if (!articleHeaderYoutubeVideo.startsWith('http'))
      articleHeaderYoutubeVideo = `https://${articleHeaderYoutubeVideo}`

    const headerYoutubeVideoUrl = new URL(articleHeaderYoutubeVideo)
    videoUrl = `https://www.youtube.com/embed/${headerYoutubeVideoUrl.searchParams.get(
      'v'
    )}`

    if (article.autoplayHeaderYoutubeVideo) videoUrl = `${videoUrl}?autoplay=1`
  } catch (err) {
    console.log(err)
  }

  return videoUrl
}

const ArticleDetail = ({ data: { article, allArticle }, pageContext }) => {
  const Layout = pageContext.country === 'US' ? NewMainLayout : MainLayout

  const [isCTAVisible, setIsCTAVisible] = useState(false)

  let articleMedia
  if (article.headerYoutubeVideo) {
    const videoUrl = handleYoutubeUrl(article)

    if (videoUrl)
      articleMedia = (
        <iframe
          title={article.title}
          id="articleYoutubeVideo"
          type="text/html"
          src={videoUrl}
          frameBorder="0"
          style={{ width: '100%', marginBottom: '2rem' }}
          height="420"
        />
      )
  }

  if (!articleMedia)
    if (article.image) {
      articleMedia = (
        <MainImage
          fluid={article.image.childImageSharp.fluid}
          itemProp="image"
          loading="eager"
        />
      )
    }

  const showRelated = true
  const GridComponent = showRelated ? Grid : GridFlex
  const shouldShowNewsletterModal =
    article.slug !== 'the-fastest-weed-delivery-service-in-california'

  return (
    <Layout shouldShowNewsletterModal={shouldShowNewsletterModal}>
      <SEO
        title={article.title}
        description={
          article.metaDescription || article.childMarkdownRemark.excerpt
        }
        localImage={
          article.image ? article.image.childImageSharp.fluid.src : null
        }
      />

      <Center>
        <GridComponent>
          <ArticleWrapper itemScope itemType="http://schema.org/Article">
            <CTA isVisible={isCTAVisible} setIsVisible={setIsCTAVisible} />

            <header>
              {articleMedia}

              <Title itemProp="headline">{article.title}</Title>
              <Article.Author
                author={article.author}
                createdAt={article.publishedAt || article.createdAt}
                timeToRead={article.childMarkdownRemark.timeToRead}
              />
            </header>

            <Content
              itemProp="articleBody"
              dangerouslySetInnerHTML={{
                __html: article.childMarkdownRemark.html,
              }}
            />
            <Article.Tags tags={article.tags} />
          </ArticleWrapper>

          {showRelated && (
            <Article.Related relatedArticles={allArticle.nodes} />
          )}
        </GridComponent>
      </Center>

      <CTA isVisible={isCTAVisible} setIsVisible={setIsCTAVisible} doNotFetch />
    </Layout>
  )
}

export default ArticleDetail
